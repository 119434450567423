import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { SERVICES } from '../data/services';

import Logo from '../images/logo.png';

const Navbar = () => {
  const [dropdownIsShown, setDropdownIsShown] = useState(false);
  const [serviceDropDown, setServiceDropDown] = useState(false);
  const [zoneDropdownIsShown, setZoneDropdownIsShown] = useState(false);
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false);
  const [subMenuIsShown, setSubMenuIsShown] = useState(false);
  const [zoneSubMenuIsShown, setZoneSubMenuIsShown] = useState(false);
  const [citySubMenuState, setSubMenuState] = useState(false);

  return (
    <div className="relative bg-white">
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <Link to="/" className="flex">
            <span className="sr-only">Super serrurier</span>
            <img
              className="h-8 w-auto sm:h-10"
              src={Logo}
              alt="Super Serrurier"
            />
          </Link>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <button
            onClick={() => setMobileMenuIsShown(true)}
            type="button"
            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">Open menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        {/*desktop*/}
        <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
          <nav className="flex space-x-10">
            <Link
              to="/"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Accueil
            </Link>
            <div className="relative">
              <button
                type="button"
                onClick={() => setZoneDropdownIsShown(!zoneDropdownIsShown)}
                className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 more_dropdown_button"
              >
                <span>Zones d'intervention</span>
                <svg
                  className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div
                className={clsx(
                  'absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0 transition ease-out duration-200 opacity-0 translate-y-1 z-10',
                  {
                    hidden: !zoneDropdownIsShown,
                    'opacity-100': zoneDropdownIsShown,
                    'translate-y-0': zoneDropdownIsShown,
                  }
                )}
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className=" relative grid gap-6 bg-white pl-5 py-6 sm:gap-8 sm:py-8 sm:pl-6">
                    <Link
                      to="/serrurier-geneve"
                      className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900">
                        Genève
                      </p>
                    </Link>

                    <Link
                      to="/vaud"
                      onMouseEnter={() => setSubMenuState(true)}
                      onMouseLeave={() => setSubMenuState(false)}
                      className="relative -m-3 p-3 block rounded-md hover:bg-gray-50 sm:flex justify-between"
                    >
                      <p className="text-base font-medium text-gray-900">
                        Vaud
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400 mr-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                      <div className="absolute">
                        {citySubMenuState && (
                          <div
                            onMouseEnter={() => setSubMenuState(true)}
                            onMouseLeave={() => setSubMenuState(false)}
                            className="sm:fixed sm:top-14 sm:left-80 bg-white sm:py-6 grid sm:gap-8 rounded-md sm:shadow-lg sm:w-48"
                          >
                            <Link
                              to="/serrurier-lausanne"
                              className="mx-3 block rounded-md hover:bg-gray-50 p-3"
                            >
                              <p className="text-base font-medium text-gray-900">
                                Lausanne
                              </p>
                            </Link>
                            <Link
                              to="/serrurier-montreux"
                              className="mx-3 block rounded-md hover:bg-gray-50 p-3"
                            >
                              <p className="text-base font-medium text-gray-900">
                                Montreux
                              </p>
                            </Link>
                          </div>
                        )}
                      </div>
                    </Link>

                    <Link
                      to="/fribourg"
                      className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900">
                        Fribourg
                      </p>
                    </Link>
                    <Link
                      to="/neuchatel"
                      className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900">
                        Neuchâtel
                      </p>
                    </Link>
                    <Link
                      to="/valais"
                      className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900">
                        Valais
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative">
              <button
                type="button"
                onClick={() => setServiceDropDown(!serviceDropDown)}
                className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 more_dropdown_button"
              >
                <span>Nos Services</span>
                <svg
                  className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>

              <div
                className={clsx(
                  'absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0 transition ease-out duration-200 opacity-0 translate-y-1 z-10',
                  {
                    hidden: !serviceDropDown,
                    'opacity-100': serviceDropDown,
                    'translate-y-0': serviceDropDown,
                  }
                )}
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    {SERVICES.map((service) => {
                      return (
                        <Link
                          key={service.name}
                          to={service.link}
                          className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                        >
                          <p className="text-base font-medium text-gray-900">
                            {service.name}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <Link
              to="/faq"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              FAQ
            </Link>

            <div className="relative">
              <button
                type="button"
                onClick={() => setDropdownIsShown(!dropdownIsShown)}
                className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 more_dropdown_button"
              >
                <span>Entreprise</span>
                <svg
                  className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>

              <div
                className={clsx(
                  'absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0 transition ease-out duration-200 opacity-0 translate-y-1 z-10',
                  {
                    hidden: !dropdownIsShown,
                    'opacity-100': dropdownIsShown,
                    'translate-y-0': dropdownIsShown,
                  }
                )}
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <Link
                      to="/a-propos"
                      className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900">
                        A propos
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Expert en dépannage d’urgence depuis 1998
                      </p>
                    </Link>
                    <Link
                      to="/team"
                      className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900">
                        Notre équipe
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Nos serruriers experts à Genève
                      </p>
                    </Link>

                    <Link
                      to="/blog"
                      className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                    >
                      <p className="text-base font-medium text-gray-900">
                        Blog
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Témoignages de clients, conseils et opinions sur tout ce
                        qui touche de loin ou de pret à la serrurerie, le tout
                        au même endroit.{' '}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div className="flex items-center md:ml-12">
            <Link
              to="/contact"
              className="text-base font-medium px-5 py-2 rounded-md shadow-sm  border-gray-200 text-white bg-secondarycolor hover:bg-secondarycolor-light"
            >
              Contact
            </Link>
          </div>
        </div>
      </div>

      {/*mobile*/}
      <div
        className={clsx(
          'absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden menu_content z-10',
          { hidden: !mobileMenuIsShown }
        )}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img className="h-8 w-auto" src={Logo} alt="Super serrurier" />
              </div>
              <div className="-mr-2">
                <button
                  onClick={() => setMobileMenuIsShown(false)}
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-6">
                <Link
                  to="/"
                  className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                >
                  <div className="ml-4 text-base font-medium text-gray-900">
                    Accueil
                  </div>
                </Link>

                <button
                  className="-m-3 p-3 flex items-center justify-center rounded-lg hover:bg-gray-50 focus:outline-none"
                  onClick={() => setZoneSubMenuIsShown(!zoneSubMenuIsShown)}
                >
                  <div className="flex items-center w-full">
                    <div className="ml-4 text-base font-medium text-gray-900">
                      Zones d'intervention
                    </div>
                  </div>
                  <span className="ml-6 h-7 flex items-center">
                    <svg
                      id="icon1"
                      className={clsx(
                        '-rotate-0 text-gray-400 h-6 w-6 transform',
                        { '-rotate-180': zoneSubMenuIsShown }
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </span>
                </button>
                <div className={clsx({ hidden: !zoneSubMenuIsShown })}>
                  <Link
                    to="/serrurier-geneve"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                  >
                    <div className="ml-2 text-base font-medium text-gray-900">
                      Genève
                    </div>
                  </Link>

                  <Link
                    to="/vaud"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                  >
                    <div className="ml-2 text-base font-medium text-gray-900">
                      Vaud
                    </div>
                  </Link>
                  <Link
                    to="/serrurier-lausanne"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                  >
                    <div className="ml-6 text-base font-medium text-gray-900">
                      Lausanne
                    </div>
                  </Link>
                  <Link
                    to="/serrurier-montreux"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                  >
                    <div className="ml-6 text-base font-medium text-gray-900">
                      Montreux
                    </div>
                  </Link>
                  <Link
                    to="/fribourg"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                  >
                    <div className="ml-2 text-base font-medium text-gray-900">
                      Fribourg
                    </div>
                  </Link>
                  <Link
                    to="/neuchatel"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                  >
                    <div className="ml-2 text-base font-medium text-gray-900">
                      Neuchâtel
                    </div>
                  </Link>
                  <Link
                    to="/valais"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                  >
                    <div className="ml-2 text-base font-medium text-gray-900">
                      Valais
                    </div>
                  </Link>
                </div>
                <button
                  className="-m-3 p-3 flex items-center justify-center rounded-lg hover:bg-gray-50 focus:outline-none"
                  onClick={() => setServiceDropDown(!serviceDropDown)}
                >
                  <div className="flex items-center w-full">
                    <div className="ml-4 text-base font-medium text-gray-900">
                      Nos Services
                    </div>
                  </div>
                  <span className="ml-6 h-7 flex items-center">
                    <svg
                      id="icon1"
                      className={clsx(
                        '-rotate-0 text-gray-400 h-6 w-6 transform',
                        { '-rotate-180': serviceDropDown }
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </span>
                </button>
                <div className={clsx({ hidden: !serviceDropDown })}>
                  {SERVICES.map((service) => {
                    return (
                      <Link
                        key={service.name}
                        to={service.link}
                        className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                      >
                        <div className="ml-2 text-base font-medium text-gray-900">
                          {service.name}
                        </div>
                      </Link>
                    );
                  })}
                </div>
                <Link
                  to="/faq"
                  className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                >
                  <div className="ml-4 text-base font-medium text-gray-900">
                    FAQ
                  </div>
                </Link>

                <button
                  className="-m-3 p-3 flex items-center justify-center rounded-lg hover:bg-gray-50 focus:outline-none"
                  onClick={() => setSubMenuIsShown(!subMenuIsShown)}
                >
                  <div className="flex items-center w-full">
                    <div className="ml-4 text-base font-medium text-gray-900">
                      Entreprise
                    </div>
                  </div>
                  <span className="ml-6 h-7 flex items-center">
                    <svg
                      id="icon1"
                      className={clsx(
                        '-rotate-0 text-gray-400 h-6 w-6 transform',
                        { '-rotate-180': subMenuIsShown }
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </span>
                </button>

                <div className={clsx({ hidden: !subMenuIsShown })}>
                  <Link
                    to="/a-propos"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                    id="apropos_link"
                  >
                    <div className="ml-4 text-base font-medium text-gray-900">
                      A propos
                    </div>
                  </Link>
                  <Link
                    to="/team"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                    id="blog_link"
                  >
                    <div className="ml-4 text-base font-medium text-gray-900">
                      Notre équipe
                    </div>
                  </Link>
                  <Link
                    to="/blog"
                    className="-m-3 p-6 flex items-center rounded-lg hover:bg-gray-50 ml-4"
                    id="blog_link"
                  >
                    <div className="ml-4 text-base font-medium text-gray-900">
                      Blog
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
          <div className="py-6 px-5">
            <div className="mt-6">
              <a
                href="tel:0791304768"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-secondarycolor hover:bg-secondarycolor-light"
              >
                Appelez nous
              </a>
              <p className="mt-6 text-center text-base font-medium text-gray-500">
                Client existant?
                <Link
                  to="/contact"
                  className="text-secondarycolor hover:text-secondarycolor-light"
                >
                  {' '}
                  Contact
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
