import { Link } from 'gatsby';
import React from 'react';
import Logo from '../images/logo.png';

const navigation = {
  solutions: [
    { name: 'Accueil', href: '/' },
    { name: 'FAQ', href: '/faq' },
  ],

  company: [
    { name: 'A propos', href: '/a-propos' },
    { name: 'Notre équipe', href: '/team' },
    { name: 'Blog', href: '/blog' },
  ],

  social: [],
};

const Footer = () => {
  return (
    <footer className="bg-textcolor" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <div className="flex gap-2 items-center">
              <img className="h-11" src={Logo} alt="Super serrurier" />
              <p className="font-secondary text-lg font-bold leading-6 text-white">
                Super serrurier
              </p>
            </div>
            <p className="font-secondary text-base leading-6 text-white">
              Serruriers d’urgence professionnels expérimentés et certifiés en
              Suisse Romande.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <Link
                  key={item.name}
                  target="_blank"
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </Link>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="hidden lg:block"></div>
              <div>
                <h3 className="text-base font-semibold leading-6 text-white">
                  General
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-gray-300"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-base font-semibold leading-6 text-white">
                  Entreprise
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-gray-300"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white pt-8 sm:mt-20 lg:mt-24">
          <p className="font-secondary text-center text-base leading-5 text-white">
            © 2020 Super Serrurier, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
