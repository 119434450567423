export const SERVICES = [
  {
    name: 'Ouverture de porte d’urgence',
    link: '/services/ouverture-porte-durgence',
    description:
      'Votre serrure est usée ou vos clés sont perdues et vous avez besoin des services d’un serrurier en urgence ? Nos experts vous arrangent le problème en un rien de temps et à un prix défiant toute concurrence.',
  },
  {
    name: 'Changement de serrure',
    description:
      'Votre serrure est endommagée ou pas assez sécurisée ? Nos artisans serruriers la remplaceront par le modèle le plus récent et le plus sécurisé pour par cher.',
    link: '/services/changement-serrure',
  },
  {
    name: 'Double de clés',
    description:
      'Prenez vos précautions en cas de perte ou de vol. Nous avons le matériel nécessaire pour reproduire tous les modèles de clés existants',
    link: '/services/double-de-cle',
  },
  {
    name: 'Blindage de porte existante',
    description:
      'Augmentez la sécurité de votre porte d’entrée ou de votre garage. Avec nos spécialistes, vous respecterez les règles de copropriété en renforçant vos portes grâce à des installations certifiées.',
    link: '/services/blindage-de-porte',
  },
  {
    name: 'Porte blindée',
    description:
      'Protégez votre habitation contre les effractions. Nos experts en serrurerie vous font bénéficier d’une protection maximale en vous installant une porte totalement blindée.',
    link: '',
  },
  {
    name: 'Coffre-fort',
    description:
      'Assurez la sécurité de vos biens et documents les plus précieux. Nous vous proposons des coffres-forts des plus robustes et résistants.',
    link: '/services/coffre-fort',
  },
  {
    name: 'Installation de porte de garage',
    description:
      'Vous voulez automatiser une porte existante ou installer une nouvelle porte de garage ? Facilitez-vous la vie avec nos modèles de qualité supérieure et à la pointe de la technologie au meilleur prix.',
    link: '/services/installation-porte-garage',
  },
  {
    name: 'Rideau métallique',
    description:
      'Protégez votre commerce contre tout type d’effraction. Nos artisans serruriers procéderont à une installation simple, pratique et robuste de rideau métallique',
    link: '/services/rideau-metallique',
  },
];
